import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import './Header.css';
import { Button, Modal } from 'react-bootstrap'
import { useState } from 'react';
import NaturalPerson from "./NaturalPerson"
import LegalEntities from './LegalEntities';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { userAction } from '../../store/user';
import UserDropdown from './UserDropdown';
import '../DataTable/DataTable.css';
import { languagesAction } from '../../store/language';
import languages from '../../data/languages';
import OutsideClickHandler from 'react-outside-click-handler';

const Header = () => {
    const lang = useSelector((state) => state.language.language);
    const [email, setEmail] = useState("");
    const [showAsideModal, setShowAsideModal] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const users = useSelector((state) => state.user);
    const [showDropdown, setShowDropdown] = useState(false);
    const dispatch = useDispatch();
    const [showAuth, setShowAuth] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [activeTab, setActiveTab] = useState('natural');
    const [errorModal, setErrorModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [successText, setSuccessText] = useState("");
    const [code, setCode] = useState("");
    const [notification, setNotification] = useState({
        show: false,
        header: "",
        body: "",
        button: ""
    });
    const [showEditProfile, setShowEditProfile] = useState(false);
    const [formData, setFormData] = useState({});
    const officesList = ["თბილისი მ.ასათიანის 14ბ", "რუსთავი-თოდრიას 15", "ქუთაისი- ჭავჭავაძის 39", "ფოთი-ბაგრატიონის 21", "ზუგდიდი-თათარაშვილის 34", "გორი", "ქობულეთი", "ბათუმი"];
    const [language, setLanguage] = useState({
        active: "Geo",
        url: 'https://www.myhome.ge/framework/templates/assets/img/flags/ka.svg'
    });
    const [formDatas, setFormDatas] = useState({
        email: "", password: ""
    });

    const [resetSteps, setResetSteps] = useState(1);

    const sendResetRequest = () => {
        if (resetSteps === 1) {
            axios.put('/api/users/reset-password', { email: email })
                .then((res) => {
                    setResetSteps(2);
                })
                .catch((err) => {
                    const message = err.response.data.message.En;
                    const description = err.response.data.description;
                    setNotification({
                        show: true,
                        header: description,
                        body: message,
                        button: "დახურვა"
                    })
                })
        }
        if (resetSteps === 2) {
            axios.put('/api/password/users/reset/check', { code: code, password: newPassword })
                .then((res) => {
                    setResetSteps(3);
                })
                .catch((err) => {
                    const message = err.response.data.message.En;
                    const description = err.response.data.description;
                    setNotification({
                        show: true,
                        header: description,
                        body: message,
                        button: "დახურვა"
                    })
                })
        }

        if (resetSteps === 3) {
            setShowResetPasswordModal(false)
        }
    }

    const editUserData = (e) => {
        e.preventDefault();

        axios.put('/api/user', formData)
            .then(() => {
                setNotification({
                    show: true,
                    header: "პროფილის ცვლილება",
                    body: "ინფორმაცია განახლებულია",
                    button: "დახურვა"
                });
                setShowEditProfile(false);
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    const loginHandler = () => {
        axios.post(`/api/users/auth`, formDatas)
            .then((response) => {
                const data = response.data.data;
                setSuccess(true);
                setSuccessText(response.data.message.En);
                setErrorModal(false);
                dispatch(userAction.setIsLoggedIn(true));
                dispatch(userAction.setUserData({
                    name: data.name,
                    surname: data.surname,
                    initials: `${data.name.slice(0, 1)}${data.surname.slice(0, 1)}`,
                    userId: data.userId,
                    role: data.role,
                    phone: data.phone,
                    country: data.country,
                    address: data.address,
                    email: data.email,
                    officeAddress: data.officeAddress
                }))
                setTimeout(() => {
                    setShowAuth(false)
                }, 1000);
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                setErrorModal(true);
                setError(message);
                setSuccess(false);
            })
    }

    return (
        <>
            <header className="header-container">
                <div className='header-content'>
                    <Link to="/"><img className='header-logo' src={`/static/image/logo.png`} alt='logo' /></Link>
                    <div className='header-unauthorized-buttons'>
                        <button className='select-language' onClick={() => {
                            if (language.active === "Geo") {
                                setLanguage({
                                    active: "En",
                                    url: "https://www.myhome.ge/framework/templates/assets/img/flags/en.svg"
                                })
                                dispatch(languagesAction.changeLanguage("En"))
                            } else {
                                setLanguage({
                                    active: "Geo",
                                    url: "https://www.myhome.ge/framework/templates/assets/img/flags/ka.svg"
                                })
                                dispatch(languagesAction.changeLanguage("Geo"))
                            }
                        }}><img src={language.url} alt='flags' /></button>
                        {
                            users.isLoggedIn === false && (
                                <div className='hide-on-mobal'>
                                    <button onClick={() => setShowAuth(true)} className='header-sign-in-button'>{languages[lang].headerButtons.auth}</button>
                                    <button className='header-sign-up-button' onClick={() => setShowSignUp(true)}>{languages[lang].headerButtons.registration}</button>
                                </div>
                            )
                        }
                        {
                            users.isLoggedIn === true && (
                                <>
                                    <OutsideClickHandler onOutsideClick={() => {
                                        setShowDropdown(false);
                                    }}>
                                        <button className='header-user-dropdown-icon' onClick={() => setShowDropdown(!showDropdown)}>
                                            {users.initials}
                                        </button>
                                    
                                    {
                                        showDropdown && <UserDropdown setUserOutside={setFormData} setShowDropdown={setShowDropdown} setShowEditProfile={setShowEditProfile} />
                                    }
                                    </OutsideClickHandler>
                                </>
                            )
                        }
                        <button className='no-background' onClick={() => setShowAsideModal(true)}><img src='/static/image/bar.svg' alt='bar-icon' className='bar-icon' /></button>
                    </div>
                </div>
            </header>

            <Modal show={showAuth} size="md" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setShowAuth(false)}>
                <Modal.Header>
                    <div className='auth-header'>
                        <img src={`/static/image/logo.png`} alt='logo' className='auth-modal-logo' />
                        <h3 className='auth-modal-title'>{languages[lang].headerButtons.auth}</h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label className='form-label'>{languages[lang].auth.email}</label>
                        <input type='email' className='form-control' onKeyDown={(e) => {
                            e.keyCode === 13 && loginHandler()
                        }} onChange={(e) => setFormDatas((prev) => ({ ...prev, email: e.target.value }))} placeholder={languages[lang].auth.email} />
                    </div>
                    <div className='form-group' style={{ marginTop: "20px" }}>
                        <label className='form-label'>{languages[lang].auth.password}</label>
                        <input type='password' onKeyDown={(e) => {
                            e.keyCode === 13 && loginHandler()
                        }} className='form-control' onChange={(e) => setFormDatas((prev) => ({ ...prev, password: e.target.value }))} placeholder={languages[lang].auth.password} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='sign-in-button' onClick={loginHandler}>{languages[lang].auth.submit}</button>
                    {errorModal && <div style={{ width: "100%" }} className="alert alert-danger">{error}</div>}
                    {success && <div style={{ width: "100%" }} className="alert alert-success">{successText}</div>}

                    <div className='forgot-password-block'>
                        <button className='forgot-password-button' onClick={() => {
                            setShowAuth(false);
                            setShowSignUp(true)
                        }}>{languages[lang].headerButtons.registration}</button>
                        <button onClick={() => {
                            setShowAuth(false);
                            setShowResetPasswordModal(true);
                        }} className='forgot-password-button'>{languages[lang].auth.resetPassTitle}</button>
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal show={showEditProfile} size="lg" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setShowEditProfile(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>პროფილის რედაქტირება</h3>
                </Modal.Header>
                <Modal.Body>
                    <form className="container" style={{ paddingTop: "0px" }} onSubmit={editUserData}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">ოფისის მისამართი</label>
                                    <select type="text" className="form-control" value={formData.officeAddress} onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, officeAddress: e.target.value }))
                                        if(e.target.value !== 'თბილისი მ.ასათიანის 14ბ'){
                                            setNotification({
                                                show: true,
                                                header: "შეტყობინება",
                                                body: "რეგიონებში იგზავნება კვირაში ერთხელ ოთხშაბათობით, საფასური ერთ რეისზე გაგზავნილ ამანათებზე ჯამში 4 ლარი",
                                                button: "დახურვა"
                                            })
                                        }
                                    }} required>
                                        <option value={""}>
                                            ოფისის მისამართი
                                        </option>
                                        {officesList.map((o, k) => {
                                            return (
                                                <option key={k * 20} value={o}>{o}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">პირადი ნომერი</label>
                                    <input type="text" className="form-control" value={formData.personalNumber} onChange={(e) => setFormData((prev) => ({ ...prev, personalNumber: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">ელ. ფოსტა</label>
                                    <input type="email" className="form-control" value={formData.email} onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">ნომერი</label>
                                    <input type="text" className="form-control" value={formData.phone} onChange={(e) => setFormData((prev) => ({ ...prev, phone: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">პაროლი</label>
                                    <input type="text" className="form-control" value={formData.password} onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 submit-form-buttons">
                            <Button variant="primary" style={{ marginRight: "20px" }} onClick={() => setShowEditProfile(false)}>დახურვა</Button>
                            <Button variant="danger" type="submit">შენახვა</Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showSignUp} size="lg" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setShowSignUp(false)}>
                <Modal.Header>
                    <div className='auth-header'>
                        <img src={`/static/image/logo.png`} alt='logo' className='auth-modal-logo' />
                        <h3 className='auth-modal-title'>Sign Up</h3>
                        <div className='sign-up-role-switcher'>
                            <button className={`${activeTab === 'natural' ? 'active-tab' : ""}`} onClick={() => setActiveTab('natural')}>{languages[lang].registration.physicalPerson}</button>
                            <button className={`${activeTab === 'legal' ? 'active-tab' : ""}`} onClick={() => setActiveTab('legal')}>{languages[lang].registration.legalEntity}</button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        activeTab === "natural" && <NaturalPerson showSignUpModal={setShowSignUp} showAuthModal={setShowAuth} />
                    }
                    {
                        activeTab === "legal" && <LegalEntities showSignUpModal={setShowSignUp} showAuthModal={setShowAuth} />
                    }
                </Modal.Body>
            </Modal>
            <Modal show={showAsideModal} onHide={() => setShowAsideModal(false)} dialogClassName="aside-modal">
                <Modal.Header>
                    {users.isLoggedIn === true ? (
                        <>
                            <button style={{ display: "block" }} className='header-user-dropdown-icon' onClick={() => setShowDropdown(!showDropdown)}>
                                {users.initials}
                            </button>
                        </>
                    ) : (
                        <>
                            <button onClick={() => {
                                setShowAuth(true);
                                setShowAsideModal(false);
                            }} className='header-sign-in-button'>ავტორიზაცია</button>
                            <button className='header-sign-up-button' onClick={() => {
                                setShowSignUp(true);
                                setShowAsideModal(false);
                            }}>რეგისტრაცია</button>
                        </>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <ul className='sidebar-content-list'>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/">{languages[lang].navigation.main}</Link></li>
                        {users.isLoggedIn && <li onClick={() => setShowAsideModal(false)} className='sidebar-item'><Link to="/my-page">{languages[lang].navigation.myPage}</Link></li>}
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/flights">{languages[lang].navigation.flights}</Link></li>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/shopping">{languages[lang].navigation.shopping}</Link></li>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/services/prices">{languages[lang].navigation.price}</Link></li>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/services/online-parcels">{languages[lang].navigation.services}</Link></li>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/useful-information/how-to-buy">{languages[lang].navigation.info}</Link></li>
                        <li className='sidebar-item' onClick={() => setShowAsideModal(false)}><Link to="/contact">{languages[lang].navigation.contact}</Link></li>
                    </ul>
                </Modal.Body>
            </Modal>

            <Modal show={showResetPasswordModal} size="md" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setShowResetPasswordModal(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>პაროლის აღდგენა</h3>
                </Modal.Header>
                <Modal.Body>
                    {resetSteps === 1 && <div className='form-group' style={{ marginTop: "20px" }}>
                        <label className='form-label'>ელ. ფოსტა</label>
                        <input type='email' className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='შეიყვანეთ ელ. ფოსტა' />
                    </div>}

                    {
                        resetSteps === 2 && (
                            <>
                                <div className='form-group' style={{ marginTop: "20px" }}>
                                    <label className='form-label'>ერთჯერადი კოდი</label>
                                    <input type='text' className='form-control' value={code} onChange={(e) => setCode(e.target.value)} placeholder='ერთჯერადი კოდი' />
                                </div>
                                <div className='form-group' style={{ marginTop: "20px" }}>
                                    <label className='form-label'>ახალი პაროლი</label>
                                    <input type='password' className='form-control' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder='ახალი პაროლი' />
                                </div>
                            </>
                        )
                    }
                    {
                        resetSteps === 3 && (
                            <div className='success-modal' style={{ display: "flex", marginLeft: "15px", alignItems: "center" }}>
                                <img src='/static/image/check.png' alt='success' />
                                <h3 style={{ color: "white", fontSize: "20px" }}> პაროლი შეცვლილია</h3>
                            </div>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={sendResetRequest}>{resetSteps === 3 ? "დახურვა" : "გაგრძელება"}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={notification.show} size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>{notification.header}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: "white" }}>{notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setNotification((prev) => ({ ...prev, show: false }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Header