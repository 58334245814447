import React from "react";
import languages from "../../data/languages";
import {useSelector} from "react-redux";

const ServicesPrices = () => {
    const lang = useSelector((state) => state.language.language);

    return(
        <div className={"content"}>
            <div className={'banner-image-container'}>
                <img src={`/static/image/price.svg`} alt="bonus points " className={'banner-image'}/>
            </div>
            <div className={"container"}>
                <p className={'title'}>{languages[lang].servicesDropdown.prices}</p>
                <p className={'title'}>{languages[lang].servicesPages.prices[0]}</p>
                <p className={'title'}>{languages[lang].servicesPages.prices[1]}</p>
                <p className={'title'}>{languages[lang].servicesPages.prices[2]}</p>
                <p className={'title'}>{languages[lang].servicesPages.prices[3]}</p>
                <p className={'title'}>{languages[lang].servicesPages.prices[4]}</p>
                <p className={'title'}>{languages[lang].servicesPages.prices[5]}</p>
            </div>
        </div>
    )
}

export default ServicesPrices